import { Selector, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { ServerCartDelivery } from '../serverCart/types';
import validateWarehouse from './helpers/validateWarehouse';

const selectSelectedDelivery: Selector<RootState, ServerCartDelivery | null> =
  createSelector(
    [
      (s) => s.app.cartType,
      (s) => s.addressManagement?.delivery,
      (s) => s.serverCart.data?.delivery || null,
    ],
    (cartType, clientCartDelivery, serverCartDelivery) => {
      if (cartType === 'client' || !cartType) {
        return clientCartDelivery;
      }

      if (!serverCartDelivery || !validateWarehouse(serverCartDelivery)) {
        return null;
      }

      return serverCartDelivery;
    },
  );

export default selectSelectedDelivery;
