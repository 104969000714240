import { desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
    .CheckboxWithLabel {
      ${typography.text};
      line-height: 17px;
      display: flex;
      align-items: flex-start;
    }

    .CheckboxWithLabel__label {
      margin-left: 10px;
      transition: 0.2s color ease-in-out;
    }

    .CheckboxWithLabel__label_disabled {
      color: var(--colors-darkenGrey3);
    }

    .CheckboxWithLabel__label_bold {
      font-weight: 600;
    }

    .CheckboxWithLabel__fullWidth,
    .CheckboxWithLabel__label_fullWidth {
      width: 100%;
    }

    @media ${desktop} {
      .CheckboxWithLabel__label:not(.CheckboxWithLabel__label_disabled) {
        cursor: pointer;
      }
    }
  `;
