import { DeliveryType, Product } from "src/redux/apiTypes";
import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import { useAppSelector } from "src/redux/hooks";
import useStoreService from "src/services/storeManagement/useStoreService";
import getUserAgent from "src/utils/api/getUserAgent";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import getGoogleCid from 'src/utils/marketing/getGoogleCid';
import useSWR from "swr";
import { useExperimentClientSideOnly } from '../modules/Experiments/hooks';

interface Params {
  count?: number;
  excludedEans?: string[];
}

const maxExcludeEansCount = 150;
const EMPTY_RECOMMENDATIONS = [];

const useRecommendations = (params: Params = {}) => {
  const { count = 30, excludedEans = []} = params;
  const { fetchConfig } = useAppConfig();
  const store = useStoreService();
  const deliveryType = useAppSelector(selectDeliveryType);
  const userIsLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const withUsingAI = !!useExperimentClientSideOnly('ai_recommendations');

  const googleCid = getGoogleCid();
  const visitorIdParam = googleCid ? `&visitor_id=${googleCid}` : '';
  const withUsingAIParam = withUsingAI ? `&use_ai_recommendations=true`  : '';

  //this param should not be in cache dependencies
  const excludedEansParam = excludedEans.length > 0
    ? `&exclude_eans=${excludedEans.slice(0, maxExcludeEansCount).join(',')}`
    : '';

  const { data, error, mutate, isValidating  } = useSWR<Product[]>(
    [store.id, deliveryType, count, userIsLoggedIn, 'recommendations'],
    getRecommendations,
  );

  const recommendationsAreFetching = (!data && !error) || isValidating;
  const recommendations = data ? data : EMPTY_RECOMMENDATIONS;

  return { recommendations, recommendationsAreFetching, mutateRecommendations: mutate };

  async function getRecommendations(storeId: string, deliveryType: DeliveryType, count: number): Promise<Product[]> {
    const { xChain, xVersion, apiRoot, language } = fetchConfig;
    const url = `${apiRoot}/stores/${storeId}/products/recommendations/?count=${count}` +
      excludedEansParam +
      visitorIdParam +
      withUsingAIParam;

    const response = await fetch(url,
      {
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
          'X-Delivery-Type': deliveryType,
          'User-Agent': getUserAgent(),
        },
        credentials: 'include',
      },
    );

    return await response.json();
  }
};

export default useRecommendations;
