import { createSelector, Selector } from '@reduxjs/toolkit';
import { NovaPoshtaType } from 'src/modules/AddressManagement/components/NovaPoshtaTab/useNovaPoshtaTabProps';
import { RootState } from '../../reducers';
import selectSelectedDelivery from './selectSelectedDelivery';

const selectNovaPoshtaType: Selector<
  RootState, NovaPoshtaType
> = createSelector(
  [
    selectSelectedDelivery,
  ],
  (delivery) => {
    return delivery?.address?.nova_poshta?.warehouse?.type || null;
  },
);

export default selectNovaPoshtaType;
