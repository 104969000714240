import classNames from 'classnames';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { desktop, lg, lg_md, md, phone, phone_portrait, phone_tablet, sm, tablet, tablet_desktop, tablet_md, tablet_md_phone } from '../../../styles/media';
import css from './Responsive.styles';

interface Props {
  lg?: boolean;
  md?: boolean;
  lgMd?: boolean;
  sm?: boolean;
  desktop?: boolean;
  tabletMd?: boolean;
  tabletMdPhone?: boolean;
  tablet?: boolean;
  phone?: boolean;
  tabletDesktop?: boolean;
  phoneTablet?: boolean;
  phonePortrait?: boolean;
  children: React.ReactNode;
  displayNoneMode?: boolean;
  dataMarkerValue?: string;
}

const mediaQueryMap = {
  lg: lg,
  md: md,
  lgMd: lg_md,
  sm: sm,
  desktop: desktop,
  tabletMd: tablet_md,
  tabletMdPhone: tablet_md_phone,
  tablet: tablet,
  phone: phone,
  tabletDesktop: tablet_desktop,
  phoneTablet: phone_tablet,
  phonePortrait: phone_portrait,
};

const Responsive = (props: Props) => {
  const { dataMarkerValue } = props;

  const deviceMode = Object.keys(props).find(key => props[key]);
  const isRenderedOnSsr = (deviceMode === 'phone' || deviceMode === 'phoneTablet');

  const mediaQueryIsObserved = useMediaQuery({ query: mediaQueryMap[deviceMode] });
  const [shouldBeRender, setShouldBeRender] = useState(isRenderedOnSsr);

  //to avoid hydration errors
  useEffect(() => {
    setShouldBeRender(mediaQueryIsObserved);
  }, [mediaQueryIsObserved]);

  const marker = dataMarkerValue
      ? dataMarkerValue
      : classNames({
        'Responsive_desktop': props.desktop,
        'Responsive_tablet': props.tablet,
        'Responsive_phone': props.phone,
        'Responsive_tablet_desktop': props.tabletDesktop,
        'Responsive_phone_tablet': props.phoneTablet,
      });

  if (props.displayNoneMode) {
    return (
      <Fragment>
        <div
          className={classNames(
            'Responsive', {
            'Responsive_lg': props.lg,
            'Responsive_md': props.md,
            'Responsive_lgMd': props.lgMd,
            'Responsive_sm': props.sm,
            'Responsive_desktop': props.desktop,
            'Responsive_tabletMd': props.tabletMd,
            'Responsive_tabletMdPhone': props.tabletMdPhone,
            'Responsive_tablet': props.tablet,
            'Responsive_phone': props.phone,
            'Responsive_tabletDesktop': props.tabletDesktop,
            'Responsive_phoneTablet': props.phoneTablet,
            'Responsive_phonePortrait': props.phonePortrait,
          })}
          data-marker={marker}
        >
          {props.children}
        </div>

        <style jsx>{css}</style>
      </Fragment>
    );
  }

  if(shouldBeRender) {
    return (
      <div data-marker={marker}>
        {props.children}
      </div>
    );
  }

  return null;
};

export default Responsive;
