import { createContext, ReactNode, useContext } from 'react';
import { Offer, ServicePage, Store } from 'src/redux/apiTypes';
import { useAppSelector } from 'src/redux/hooks';
import { Toggles } from 'src/services/toggles/types';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import ChainContext from './chain/chainContext';
import { Chain } from './chain/types';
import useChainLogic from './chain/useChainLogic';
import DeliveryPresets from './deliveryPresets/deliveryPresetsContext';
import useDeliveryPresetsService from './deliveryPresets/useDeliveryPresetsService';
import useFavoritesService, { FavoritesService } from './favorites/useFavoritesService';
import { LocalizeFunc, TranslationDictionary } from './localization/types';
import { useLocalizationService } from './localization/useLocalizationService';
import useMainPromotionService from './mainPromotion/useMainPromotionService';
import { NotificationService } from './notification/types';
import useNotificationService from './notification/useNotificationService';
import ServicePagesContext from './servicePages/servicePagesContext';
import useServicePagesLogic from './servicePages/useServicePagesLogic';
import useStoreLogic from './storeManagement/useStoreLogic';
import TogglesContext from './toggles/togglesContext';
import useTogglesService from './toggles/useTogglesService';
import * as Sentry from '@sentry/nextjs';

export interface Services {
  favorites: FavoritesService;
  localization: LocalizeFunc;
  notification: NotificationService;
  store: Store;
  mainPromotion: Offer | null;
}

interface ServicesProviderProps {
  toggles: Toggles;
  children: ReactNode;
  translations: TranslationDictionary;
  chainData: Chain;
  initialServicePages: ServicePage[];
  initialStore: Store;
  initialMainPromotion: Offer | null;
}

export const ServicesContext = createContext<Services>(null);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = (props: ServicesProviderProps) => {
  const {
    toggles,
    children,
    chainData,
    translations,
    initialServicePages,
    initialStore,
    initialMainPromotion,
  } = props;
  const { host } = useAppConfig();
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const notification = useNotificationService();
  const store = useStoreLogic(initialStore);
  const mainPromotion = useMainPromotionService(initialMainPromotion);
  const favorites = useFavoritesService(isLoggedIn, notification.openFavoriteNotification);
  const localization = useLocalizationService(translations);
  const deliveryPresets = useDeliveryPresetsService(isLoggedIn, store);
  const togglesState = useTogglesService(toggles);
  const chainState = useChainLogic(chainData);
  const servicePages = useServicePagesLogic({
    trueHost: host,
    /**
     * optional chaining is needed to avoid unexpected errors when chainState is null
     * https://sentry.zakaz.global/organizations/zakaz/issues/8635/events/?project=62
    */
    chainHost: chainState?.hostname,
    initialData: initialServicePages,
  });

  /**
   * to investigate problem
   * https://sentry.zakaz.global/organizations/zakaz/issues/8635/events/?project=62
   */
  if (!chainState) {
    Sentry.captureException("chainData is null", { extra: JSON.parse(JSON.stringify(props)) });
  }

  const contextValue = {
    favorites,
    localization,
    notification,
    store,
    mainPromotion,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <DeliveryPresets.Provider value={deliveryPresets}>
        <TogglesContext.Provider value={togglesState}>
          <ChainContext.Provider value={chainState}>
            <ServicePagesContext.Provider value={servicePages}>
              {children}
            </ServicePagesContext.Provider>
          </ChainContext.Provider>
        </TogglesContext.Provider>
      </DeliveryPresets.Provider>
    </ServicesContext.Provider>
  );
};
